// ** React Imports
import React from 'react';
import ReactDOM from 'react-dom';

// ** Third party imports
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import PWAPrompt from 'react-ios-pwa-prompt';

// ** Component imports
import './index.css';
import { store } from './redux/storeConfig';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import GlobalStyle from '../src/styles/GlobalStyle';
import reportWebVitals from './reportWebVitals';
import { Interceptor } from './utility/interceptor';

Interceptor(store)
const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <PWAPrompt
      promptOnVisit={1}
      timesToShow={3}
      copyClosePrompt='Close'
      permanentlyHideOnDismiss={false}
    />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
        <ToastContainer />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
