/* eslint-disable no-unused-vars */
import axios from "axios";
import { toast } from "../components/helpers/toast";
import { Configuration } from "../configuration";
import { store } from "../redux/storeConfig";

const { dispatch } = store;

export const Interceptor = (store) => {
  axios.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (error) => {
      if (
        error?.response?.data?.error ===
          "Your session is expired please logout and login again." ||
        error?.response?.data?.status === 401
      ) {
        dispatch({ type: "LOGOUT", payload: {} });
        dispatch({ type: "CLEAR_STORE" });
        localStorage.removeItem("token");
      }

      if (
        error?.response?.data?.error !== "User dose not exist" &&
        error?.response?.data?.error !==
          "Specified method is invalid for this resource" &&
        error?.response?.data?.error !== "Customer creation restricted." &&
        error?.response?.data?.error !== "Please select your home ground first"
      )
        toast(`${error?.response?.data?.error || error}`, "error");
      return Promise.reject(error);
    }
  );
};
