export const Configuration = {
  user: {
    emailCheck: "/username-exist-or-not",
    login: "/login",
    register: "/register",
    socialLogin: "/social-login",
    appleLogin: "/apple-login",
    generateOTP: "otp-forgot-password",
    resetPassword: "forgot-password",
    addPersonalInfo: "userprofile/",
    editName: "useredit",
    changePassword: "change-password",
    changeEmail: "email-update",
    verifyEmail: "email-verify",
    resendLink: "email-expire",
    getHomeData: "/team-members/my_games/",
    resendEmail: 'resend-email/',
    phoneUpdate: 'user-phone-update/',
    logout: "api-auth/logout/",
  },
  booking: {
    groundList: "all_grounds/",
    setHomeGround: "user_home_ground/",
    timeslotList: "time_slots/",
    types: "booking_types/",
    book: "bookings/",
    bookingSummary: "booking-summary/",
    paymentStatus: "bookings_payment/",
    inviteMobile: "mobile-user/",
    inviteMember: "team-members/",
    inviteDetail: "invite-detail",
    acceptInvite: "accept-invite",
    playerInvite: "single-invite",
    myInvite: "team-members/my_invite/",
    declineInvite: "decline-invite",
    decideInvite: "deciding-invite",
    promoCode: "promo-code/",
    validPromoCode: "promo-code/p_code/",
    savePlayers: "team-members/confirm_player/",
    saveBibs: "team-members/player_bib/",
    acceptCount: "accept_player_count",
    blockPitch: "block-pitch/",
    blockTimeslot: "block-time/",
    blockSearch: "block-booking/",
    blockBooking: "block-bookings/",
    blockAmount: "block-book-amount-calc",
    directPay: "direct-pay",
    schedulePay: "schedule-pay",
    bookingTypes: "master-booking-type/",
    blockDay: "block-book-day",
    relatedDate: "get_related_dates/",
    terms: "terms-conditions/",
    relatedDateReschedule: "get_related_dates_rescheduling/",
    rescheduleBooking: "reschedule-booking/",
    rescheduleBlockBooking: "reschedule_booking/",
    cancelFullBooking: "cancel_full_booking/",
    cancelblockBooking: "cancel2-block-booking/",
    cancelFreeBooking: "cancel_free_booking/",
    bookingDelete: "booking-auto-delete",
    payments: "team-payment",
    removePlayer: "team-payment/remove_player/",
    scheduleOverride: 'override/',
    notification: 'get-notifications',
    updateNotification: 'update-notifications-status',
    expireList: 'game-expire',
    oneOffCancel: 'cancel_one_off_booking/',
  },
  stripe: {
    createPaymentIntent: "payment",
    listSavedCard: "save-cards",
    createApplePaymentIntent: "payment_intent_apple",
    paymentIntentAddCard: "setup-intent",
    removeCard: "remove-card",
    updateCard: "save-card-update",
    markDefault: "default-card",
    customerCreate: "create-stripe-customer",
    cardCreate: "create-card",
    retrieveCustomer: "retrieve-customer",
  },
  referee: {
    register: "register-as-referee",
    search: "fixture-from-ground",
    fixture: "fixture",
  },
};
