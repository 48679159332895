// ** Router Import
import React from "react";
import Router from "./router/Router";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/custom.scss";
import { useLoadScript } from "@react-google-maps/api";

const App = () => {
  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY, // Add your API key
  });

  return <Router />;
};
export default App;
