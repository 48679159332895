import { createGlobalStyle } from "styled-components";
import chevron from "../assets/icons/right-chevron.svg";
import excellent from "../assets/icons/tick-excellent.svg";
import average from "../assets/icons/tick-average.svg";
import weak from "../assets/icons/tick-weak.svg";
import good from "../assets/icons/tick-good.svg";
import tick from "../assets/icons/tick.svg";

export default createGlobalStyle`
.cursor-pointer {
	cursor: pointer;
}

.bold {
	font-weight: 900 !important;
}

.fw-400 {
	font-weight: 400;
}

h5 {
	font-weight: bold;
	font-size: 22px;
	flex: 1;
	text-align: center;
}

h4 {
	font-weight: 700;
	font-size: 16px;
	color: #494949;
}

.container {
	max-width: 376px;
}

.pd {
	padding-left: 20px;
	padding-right: 20px;
}

body {
	font-family: SFProText;
	background: #fafaf9;
}

.list-unstyled {
	padding-left: 0;
	list-style: none;
}

.login-wrp {
	background: #f6f6f6;
	background-size: cover;
	max-width: 767px;
	margin: 0 auto;
	color: #fff;
	text-align: center;
	padding-top: 30px;
	padding-bottom: 30px;
	min-height: 100vh;
}

.action-btn {
	width: 100%;
	padding: 17px;
	border-radius: 27px;
	font-size: 16px;
	font-weight: bold;
	color: #fff;
	border: 0;
}

.action-btn-red {
	width: 100%;
	padding: 17px;
	border-radius: 27px;
	font-size: 16px;
	font-weight: bold;
	color: #fff;
	background-image: linear-gradient(270deg, #7B0B0B, #6A1B1B) !important;
	border: 0;
}

.action-btn-new {
	width: 100%;
	padding: 17px;
	border-radius: 27px;
	font-size: 16px;
	font-weight: bold;
	color: #fff;
}

.action-btn-border {
	width: 100%;
	padding: 17px;
	border-radius: 27px;
	font-size: 16px;
	font-weight: bold;
	color: #000000;
	background-color: transparent;
	border: 1px solid #000000;
}

.action-btn-oulined {
	width: 100%;
	height: 54px;
	border-radius: 27px;
	font-size: 16px;
	font-weight: bold;
	color: #ff6d00;
	border: 1px solid #ff6d00;
	&:hover {
		color: #ff6d00 !important;
		background-image: #fff !important;
		border: 1px solid #ff6d00 !important;
	}
	&:active,
	&:focus {
		color: #ff6d00 !important;
		background-image: #fff !important;
		border: 1px solid #ff6d00 !important;
	}
	&:disabled {
		color: #ff6d00 !important;
		background-image: #fff !important;
		border: 1px solid #ff6d00 !important;
	}
}

.action-btn-oulined-black {
	width: 100%;
	height: 54px;
	border-radius: 27px;
	font-size: 16px;
	font-weight: bold;
	color: #000;
	border: 1px solid #000;
	&:hover {
		color: #000 !important;
		background-color: #fff !important;
		border: 1px solid #000 !important;
	}
	&:active,
	&:focus {
		color: #000 !important;
		background-color: #fff !important;
		border: 1px solid #000 !important;
	}
	&:disabled {
		color: #000 !important;
		background-color: #fff !important;
		border: 1px solid #000 !important;
	}
}

.btn-sm {
	padding: 5px;
	margin-top: 1rem;
}

.btn-xs {
	padding: 1px !important;
}

.action-btn-sm {
	width: 141px;
	line-height: 1px;
	padding: 16px;
	border-radius: 27px;
	font-size: 16px;
	font-weight: bold;
	color: #fff;
	background-image: linear-gradient(270deg, #ff8100, #ff0000) !important;
	border: 0;
}

.action-btn-sm-oulined {
	width: 141px;
	line-height: 1px;
	padding: 17px;
	border-radius: 27px;
	font-size: 16px;
	font-weight: bold;
	color: #000;
	background: #fff;
	border: 1px solid #000;
	&:hover,
	&:active,
	&:focus {
		color: #000 !important;
		background: #fff !important;
		border: 1px solid #000 !important;
	}
}

.btn-inline {
	width: 65px;
	height: 32px;
}

.form-check-input:checked {
	background-color: #ff6d00 !important;
	border-color: #ff6d00 !important;
}

.form-check-input:focus {
	box-shadow: none;
}

.btn-end {
	position: absolute;
	right: 11px !important;
	top: 8px !important;
	font-size: 14px;
}

.icon-start {
	position: absolute;
	left: 5px !important;
	top: 14px !important;
	font-size: 14px;
}

.arrow {
	position: relative;
	&::after {
		position: absolute;
		background:url(${chevron}) no-repeat;
		content: '';
		right: 10px;
		top: 13px;
		z-index: 1;
		width: 24px;
		height: 24px;
	}
}

.done {
	position: relative;
	&::before {
		position: absolute;
		background:url(${tick}) no-repeat;
		content: '';
		right: 10px;
		top: 17px;
		z-index: 1;
		width: 24px;
		height: 24px;
	}
}

.password-strength-excellent {
	font-size: 14px;
	margin-top: 11px;
	margin-bottom: 17px;
	padding-left: 24px;
	background-size: 16px;
}

.password-strength {
	font-size: 14px;
	margin-top: 11px;
	margin-bottom: 17px;
	padding-left: 24px;
	background-size: 16px;
}

.excellent {
	background:url(${excellent}) no-repeat left;
}

.average {
	background:url(${average}) no-repeat left;
}

.good {
	background:url(${good}) no-repeat left;
}

.weak {
	background:url(${weak}) no-repeat left;
}

.invalid-feedback {
	font-size: 14px;
	font-weight: bold;
	margin-top: 11px;
	color: #FC5640;
}

.invalid {
	font-size: 14px;
	font-weight: bold;
	margin-top: 11px;
	color: #FC5640;
}

.bottom-sc {
	position: fixed;
	bottom: 20px;
	width: 100%;
	left: 0px;
	padding: 0 20px;
	left: 0;
	right: 0;
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media only screen and (orientation: landscape) {
		position: relative;
		padding-left: 0;
		padding-right: 0;
		bottom: auto;
		margin-top: 20px;
	}
	.forgot-email {
		margin-top: 17px;
		text-align: center;
		color: #5C5C5C;
		font-size: 14px;
		display: block;
	}
}

.show-pswd {
	position: absolute;
	right: 18px;
	top: 16px;
	font-size: 14px;
	text-decoration: underline;
	cursor: pointer;
	color: #363636;
}

.icon-inline {
	position: absolute;
	right: 11px !important;
	top: 14px !important;
	font-size: 14px;
}

.form-wrp {
	position: relative;
	.form-control,
	.form-select {
		border: 1px solid #C9C9C9;
		height: 52px;
		font-size: 14px;
	}
	.form-label {
		color: #000;
	}
	.form-select {
		padding-top: 22px;
		padding-left: 10px;
	}
	.no-label {
		padding-top: 8px;
	}
	label {
		color: #D6D6D6;
		font-size: 12px;
		margin-bottom: 0;
		position: absolute;
		top: 16px;
		left: 20px;
	}
	&.focus {
		label {
			top: 7px;
			left: 10px;
		}
		.form-control {
			padding: 27px 10px 4px;
		}
	}
}

.form-new {
	label {
		top: 6px;
	}
	.form-control {
		padding: 27px 10px 4px;
	}
}

.loader-wrp {
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1056;
	left: 0;
	top: 0;
	background: rgba(0, 0, 0, 0.2);
}

.innerpage-outer-cmn {
	max-width: 767px;
	margin: 0 auto;
	margin-bottom: 70px;
}

.inner-title {
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 12px;
}

.card-head {
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 9px;
}

.was-validated .form-control:invalid {
	border: 3px solid #FC5640 !important;
	background-image: none;
}

.was-validated .form-control:valid {
	background-image: none;
}

.form-control.is-invalid {
	background-image: none;
}

.btn:hover,
.btn-primary:focus,
.btn:active,
.btn.active,
.open>.dropdown-toggle.btn {
	color: #fff;
	background-color: #5c5c5c;
	border-color: #5c5c5c;
	/*set the color you want here*/
}

.loader-wrp {
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1056;
	left: 0;
	top: 0;
	background: rgba(0, 0, 0, 0.2);
}

.link-style {
	font-size: 14px;
	font-weight: 400;
	cursor: pointer;
}

.card-details {
	padding-left: 12px;
	.card-expiry {
		width: 134px;
		margin: 13px 0px;
	}
	.card-wrp {
		background: #fff;
		border: 1px solid #c9c9c9;
		border-radius: 5px;
		.card-label {
			font-size: 12px;
			padding-top: 5px;
		}
	}
	.card-error {
		border: 2px solid #FC5640;
	}
}

.underline {
	text-decoration: underline !important;
}

.data-error {
	font-size: 14px;
	color: #494949;
	font-weight: 400;
	text-decoration: none;
	pointer-events: none;
}

.default-badge {
	font-weight: 700;
	background: #eaeaea !important;
	height: 28px;
	border-radius: 14px;
	font-size: 14px;
	color: #000 !important;
	line-height: 2px;
	cursor: pointer;
}

.default-card {
	font-weight: 900;
	font-size: 14px;
	text-decoration: none !important;
	color: #000;
}

.action-btn-danger {
	width: 141px;
	line-height: 1px;
	padding: 17px;
	border-radius: 27px;
	font-size: 16px;
	font-weight: bold;
	color: #fff;
	background-image: linear-gradient(270deg, #7B0B0B, #6A1B1B) !important;
	border: 0;
}
`;
