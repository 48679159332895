// **  Initial State
const initialState = {
  bookingDetails: null,
  bookingList: null,
  gametypeList: null,
  groundList: null,
  isLoading: false,
  listLoader: false,
  pitchLoader: false,
  monthlyBookings: [],
  pitchList: null,
  stripeCustomer: null,
  status: null,
  inviteLoader: false,
  inviteDetails: null,
  allInvites: null,
  isInviteLoading: false,
  blockLoader: false,
  blockPitch: null,
  blockTLoader: false,
  blockTimeslot: null,
  searchLoader: false,
  blockSearch: null,
  blockBookLoader: false,
  blockDetails: null,
  blockAmountLoader: false,
  blockAmount: null,
  typeLoader: false,
  bookingTypes: null,
  daysLoader: false,
  blockDays: null,
  rescheduleBooking: null,
};

export const booking = (state = initialState, action) => {
  switch (action.type) {
    case "LOADER_START":
      return {
        ...state,
        isLoading: true,
      };
    case "LOADER_STOP":
      return {
        ...state,
        isLoading: false,
      };
    case "LIST_LOADER_START":
      return {
        ...state,
        listLoader: true,
      };
    case "LIST_LOADER_STOP":
      return {
        ...state,
        listLoader: false,
      };
    case "SET_STATUS":
      return {
        ...state,
        status: action.payload,
      };
    case "CLEAR_STATUS":
      return {
        ...state,
        status: null,
      };
    case "SET_GROUND_LIST":
      return {
        ...state,
        groundList: action.payload,
      };
    case "SET_MONTHLY_BOOKINGS":
      return {
        ...state,
        monthlyBookings: action.payload,
      };
    case "SET_BOOKING_DETAILS":
      return {
        ...state,
        bookingDetails: action.payload,
      };
    case "SET_PITCH_LIST":
      return {
        ...state,
        pitchList: action.payload,
      };
    case "SET_TIMESLOT_LIST":
      return {
        ...state,
        timeslotList: action.payload,
      };
    case "SET_GAME_TYPES":
      return {
        ...state,
        gametypeList: action.payload,
      };
    case "SET_BOOKING":
      return {
        ...state,
        bookingList: action.payload,
      };
    case "SET_SAVED_CARDS":
      return {
        ...state,
        savedCard: action.payload,
      };
    case "CLEAR_GROUND_LIST":
      return {
        ...state,
        groundList: null,
      };
    case "CLEAR_BOOKING_DETAILS":
      return {
        ...state,
        bookingDetails: null,
      };
    case "SET_STRIPE_CUSTOMER":
      return {
        ...state,
        stripeCustomer: action.payload,
      };
    case "CLEAR_TIMESLOT":
      return {
        ...state,
        pitchList: null,
      };
    case "PITCH_LOADER_START":
      return {
        ...state,
        pitchLoader: true,
      };
    case "PITCH_LOADER_STOP":
      return {
        ...state,
        pitchLoader: false,
      };
    case "LOADER_INVITE_START":
      return {
        ...state,
        inviteLoader: true,
      };
    case "LOADER_INVITE_STOP":
      return {
        ...state,
        inviteLoader: false,
      };
    case "SET_INVITE_DETAILS":
      return {
        ...state,
        inviteDetails: action.payload,
      };
    case "START_GET_MY_INVITES_LOADER":
      return {
        ...state,
        isInviteLoading: true,
      };
    case "STOP_GET_MY_INVITES_LOADER":
      return {
        ...state,
        isInviteLoading: false,
      };
    case "GET_MY_INVITES":
      return {
        ...state,
        allInvites: action.payload,
      };
    case "LOADER_START_BPITCH":
      return {
        ...state,
        blockLoader: true,
      };
    case "LOADER_STOP_BPITCH":
      return {
        ...state,
        blockLoader: false,
      };
    case "SET_BLOCK_PITCH":
      return {
        ...state,
        blockPitch: action.payload,
      };
    case "LOADER_START_BTIMESLOT":
      return {
        ...state,
        blockTLoader: true,
      };
    case "LOADER_STOP_BTIMESLOT":
      return {
        ...state,
        blockTLoader: false,
      };
    case "SET_BLOCK_TIMESLOT":
      return {
        ...state,
        blockTimeslot: action.payload,
      };
    case "LOADER_START_SEARCH":
      return {
        ...state,
        searchLoader: true,
      };
    case "LOADER_STOP_SEARCH":
      return {
        ...state,
        searchLoader: false,
      };
    case "SET_BLOCK_SEARCH":
      return {
        ...state,
        blockSearch: action.payload,
      };
    case "LOADER_BLOCK_START":
      return {
        ...state,
        blockBookLoader: true,
      };
    case "LOADER_BLOCK_STOP":
      return {
        ...state,
        blockBookLoader: false,
      };
    case "SET_BLOCK_DETAILS":
      return {
        ...state,
        blockDetails: action.payload,
      };
    case "LOADER_START_BAMOUNT":
      return {
        ...state,
        blockAmountLoader: true,
      };
    case "LOADER_STOP_BAMOUNT":
      return {
        ...state,
        blockAmountLoader: false,
      };
    case "SET_BLOCK_AMOUNT":
      return {
        ...state,
        blockAmount: action.payload,
      };
    case "LOADER_TYPES_START":
      return {
        ...state,
        typeLoader: true,
      };
    case "LOADER_TYPES_STOP":
      return {
        ...state,
        typeLoader: false,
      };
    case "SET_BOOKING_TYPES":
      return {
        ...state,
        bookingTypes: action.payload,
      };
    case "LOADER_BDAYS_START":
      return {
        ...state,
        daysLoader: true,
      };
    case "LOADER_BDAYS_STOP":
      return {
        ...state,
        daysLoader: false,
      };
    case "SET_BLOCK_DAYS":
      return {
        ...state,
        blockDays: action.payload,
      };
    case "SET_RESCHEDULE_BOOKING":
      return {
        ...state,
        rescheduleBooking: action.payload,
      };
    default:
      return state;
  }
};
