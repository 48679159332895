/* eslint-disable import/no-anonymous-default-export */
import { lazy } from "react";

export default [
  {
    path: "/email-login",
    element: lazy(() => import("../../components/Login/EmailLogin")),
    auth: false,
  },
  {
    path: "/email-login/sign-up",
    element: lazy(() => import("../../components/Login/SignUp")),
    auth: false,
  },
  {
    path: "/email-login/password",
    element: lazy(() => import("../../components/Login/EmailLoginPassword")),
    auth: false,
  },
  {
    path: "/email-login/forgot-password",
    element: lazy(() => import("../../components/Login/ForgotPassword")),
    auth: false,
  },
  {
    path: "/email-login/reset-code",
    element: lazy(() => import("../../components/Login/PasswordResetCode")),
    auth: false,
  },
  {
    path: "/email-login/reset-password",
    element: lazy(() => import("../../components/Login/ResetPassword")),
    auth: false,
  },
  {
    path: "/email-login/set-password",
    element: lazy(() => import("../../components/Login/ResetPassword")),
    auth: false,
  },
  {
    path: "/email/verify",
    element: lazy(() => import("../../components/Login/EmailVerify")),
    auth: false,
  },
  {
    path: '/email-resend',
    element: lazy(() => import('../../components/Login/EmailResend')),
    auth: false,
  },
  {
    path: "/invite/:token",
    element: lazy(() => import("../../components/Login/Invite")),
    auth: false,
  },
  {
    path: "/:token",
    element: lazy(() => import("../../components/Login/Invite")),
    auth: false,
  },
];
