/* eslint-disable import/no-anonymous-default-export */
import { lazy } from "react";

export default [
  {
    path: "/leagues",
    element: lazy(() => import("../../components/Leagues")),
    auth: true,
  },
  {
    path: "/league-details",
    element: lazy(() => import("../../components/Leagues/Details")),
    auth: true,
  },
  {
    path: "/league-join-ind",
    element: lazy(() => import("../../components/Leagues/JoinInd")),
    auth: true,
  },
  {
    path: "/league-join-team",
    element: lazy(() => import("../../components/Leagues/JoinTeam")),
    auth: true,
  },
  {
    path: "/league-confirm",
    element: lazy(() => import("../../components/Leagues/Confirm")),
    auth: true,
  },
];
