// ** Routes Imports
import DashboardRoutes from "./routes/DashboardRoutes";
import LoginRoutes from "./routes/LoginRoutes";
import BookingRoutes from "./routes/BookingRoutes";
import LeaguesRoutes from "./routes/LeaguesRoutes";
import RefereeRoutes from "./routes/RefereeRoutes";

// ** Default Route
const DefaultRoute = "/home";

// ** Merge Routes
const RouteList = [
  ...LoginRoutes,
  ...DashboardRoutes,
  ...BookingRoutes,
  ...LeaguesRoutes,
  ...RefereeRoutes,
];

export { DefaultRoute, RouteList };
